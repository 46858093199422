// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-instagram-instagram-page-js": () => import("./../../src/components/instagram/InstagramPage.js" /* webpackChunkName: "component---src-components-instagram-instagram-page-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-athlete-signup-js": () => import("./../../src/pages/athlete_signup.js" /* webpackChunkName: "component---src-pages-athlete-signup-js" */),
  "component---src-pages-athletes-js": () => import("./../../src/pages/athletes.js" /* webpackChunkName: "component---src-pages-athletes-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-made-by-js": () => import("./../../src/pages/made-by.js" /* webpackChunkName: "component---src-pages-made-by-js" */),
  "component---src-pages-organization-signup-js": () => import("./../../src/pages/organization_signup.js" /* webpackChunkName: "component---src-pages-organization-signup-js" */),
  "component---src-pages-organizations-js": () => import("./../../src/pages/organizations.js" /* webpackChunkName: "component---src-pages-organizations-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-signup-js": () => import("./../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

